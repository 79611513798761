// reusable custom button for save, update and cancel buttons

 const CustomButton = {

    backgroundColor: 'orange',
    color: 'black',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    
    
};

export default CustomButton;